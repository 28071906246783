import * as React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CoingeckoProvider } from '../contexts/coingecko';
import { ConnectionProvider } from '../contexts/ConnectionContext';
import { LoaderProvider } from '../components/Loader';
import { SPLTokenListProvider } from '../contexts/tokenList';
import { WalletProvider } from '../contexts/WalletContext';
// import { AppLayout } from './Layout';
import { Claim } from './Claim';
export var App = function () {
    var muiDarkTheme = createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: '#ea4a4a',
            },
            secondary: {
                main: '#404040',
            },
            background: {
                default: '#fbf6f3',
            },
        },
        typography: {
            fontFamily: 'Poppins',
        },
    });
    return (React.createElement(ThemeProvider, { theme: muiDarkTheme },
        React.createElement(BrowserRouter, null,
            React.createElement(ConnectionProvider, null,
                React.createElement(SPLTokenListProvider, null,
                    React.createElement(CoingeckoProvider, null,
                        React.createElement(LoaderProvider, null,
                            React.createElement(WalletProvider, null,
                                React.createElement(Switch, null,
                                    React.createElement(Route, { exact: true, path: "/" }),
                                    React.createElement(Route, { exact: true, path: "/claim", component: Claim }))))))))));
};
export default hot(module)(App);
